<template>
  <PasswordChangePresentation
    :error-code="errorCode"
    @forgotPasswordSubmit="handleForgotPasswordSubmit"
  />
</template>

<script>
import PasswordChangePresentation from '@/components/auth/PasswordChangePresentation.vue'
import { cognitoForgotPasswordSubmit } from '@/assets/modules/cognito'

export default {
  name: 'PasswordChange',
  components: { PasswordChangePresentation },
  metaInfo() {
    return {
      title: this.$t('パスワード変更'),
    }
  },
  data() {
    return {
      errorCode: '',
    }
  },
  beforeRouteEnter(to, _from, next) {
    // クエリにメールアドレスが付与されていない場合、メールアドレスの形式ではない場合はログイン画面にリダイレクト
    if (
      !to.query.username ||
      !/^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(
        to.query.username
      )
    ) {
      next({
        name: 'Login',
      })
      return
    }

    next()
  },
  computed: {
    username() {
      return this.$route.query.username
    },
  },
  methods: {
    async handleForgotPasswordSubmit({ code, password }) {
      const result = await cognitoForgotPasswordSubmit(
        this.username,
        code,
        password
      )

      if (result.isError) {
        this.errorCode = result.data.code
        return
      }

      this.$router.push({ name: 'Login' })
    },
  },
}
</script>
