<template>
  <PSignupForm @submit="handleSubmit">
    <template slot="title">
      {{ $t('パスワード変更') }}
    </template>
    <template slot="contents">
      <p
        class="p-authSection__lead content"
        v-t="
          'パスワード変更します。\n新しいパスワードは、半角英数字（※英字は大文字・小文字）を含む8桁以上で設定してください。'
        "
      />
      <div class="p-authInputs">
        <PAuthInput
          class="p-authInputs__input"
          v-model.trim="$v.code.$model"
          :placeholder="$t('検証コード')"
          autocomplete="off"
          required
          :is-error="codeError"
        />
        <PAuthInput
          class="p-authInputs__input"
          type="password"
          v-model.trim="$v.password.$model"
          :placeholder="$t('新しいパスワード')"
          autocomplete="new-password"
          required
          :is-error="passwordError"
        />
        <PAuthInput
          class="p-authInputs__input"
          type="password"
          v-model.trim="$v.passwordConfirm.$model"
          :placeholder="$t('新しいパスワード（確認）')"
          autocomplete="new-password"
          required
          :is-error="passwordConfirmError"
        />
        <p
          v-if="passwordConfirmFailed"
          class="p-authInputs__errorText"
          v-t="'新しいパスワードと、新しいパスワード（確認）が一致しません。'"
        />
        <p
          v-else-if="errorCode"
          class="p-authInputs__errorText"
          v-t="getErrorMessage(errorCode)"
        />
      </div>
      <button
        type="submit"
        class="p-authSection__button c-button"
        v-t="'パスワード変更'"
        :disabled="$v.$invalid"
      />
    </template>
  </PSignupForm>
</template>

<script>
import PAuthInput from '@/components/auth/PAuthInput.vue'
import PSignupForm from '@/components/auth/PSignupForm.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'PasswordChangePresentation',
  components: {
    PAuthInput,
    PSignupForm,
  },
  props: {
    errorCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      code: '',
      password: '',
      passwordConfirm: '',
      passwordConfirmFailed: false,
    }
  },
  validations: {
    code: {
      required,
    },
    password: {
      required,
    },
    passwordConfirm: {
      required,
    },
  },
  computed: {
    codeError() {
      return (
        this.$v.code.$error ||
        (!this.passwordConfirmFailed &&
          this.errorCode === 'CodeMismatchException')
      )
    },
    passwordError() {
      return (
        this.$v.password.$error ||
        this.passwordConfirmFailed ||
        this.errorCode === 'InvalidPasswordException'
      )
    },
    passwordConfirmError() {
      return (
        this.$v.passwordConfirm.$error ||
        this.passwordConfirmFailed ||
        this.errorCode === 'InvalidPasswordException'
      )
    },
  },
  methods: {
    handleSubmit() {
      // パスワードとパスワード（確認）値が一致しているか確認
      this.passwordConfirmFailed = this.password !== this.passwordConfirm

      if (!this.passwordConfirmFailed) {
        this.$emit('forgotPasswordSubmit', {
          code: this.code,
          password: this.password,
        })
      }
    },
    getErrorMessage(code) {
      switch (code) {
        case 'CodeMismatchException':
          return '検証コードを確認してください。'
        case 'InvalidPasswordException':
          return 'パスワードは、大文字、小文字、数字を含む、8桁以上の値を設定してください。'
        case 'LimitExceededException':
          return '試行制限に達しました。時間をあけて再度お試しください。'
      }
    },
  },
}
</script>
